<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>教研中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/evaluation' }">测评</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
                <el-breadcrumb-item>{{ name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- <div class="course-detail-title" style="">
            <div style="padding: 10px">
                <div class="fl-l margin-l-sm">
                    <p class="title">{{ name }}</p>
                </div>
                <div class="clear"></div>
            </div>
        </div> -->
        <div class="padding-t-sm padding-b-sm" style="    text-align: right;">
            <span style="margin-right: 20px;">

                <el-button @click="$router.push('/evaluation/details/' + id + '/addclass')" type="primary"
                    size="small">新增</el-button>

            </span>
        </div>
        <div class="margin-sm">
            <el-table :header-cell-style="{ padding: 0 }" :cell-style="{ textAlign: 'center' }" ref="singleTable"
                :data="tableData" highlight-current-row v-loading="tableLoading" style="width: 100%; background: #fff">
                <el-table-column label="ID" property="id" align="center" width="80">
                </el-table-column>



                <el-table-column label="年级" property="grade" align="center">
                </el-table-column>
                <el-table-column label="包含阅读材料" property="updated_at" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.has_read_content === 1 ? "是" : "否" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="阅读材料内容" property="read_content" align="center">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.has_read_content === 1" type="primary" @click="viewContent(scope.row)">查看内容</el-link>
                    </template>
                </el-table-column>


                <el-table-column label="题库名称" property="question_bank_name" align="center">
                </el-table-column>
                <el-table-column label="创建人" property="creator_name" align="center">
                </el-table-column>
                <el-table-column label="创建时间" property="created_at" align="center">
                </el-table-column>
                <el-table-column label="更新时间" property="updated_at" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <span style="margin-right: 5px">
                            <el-link type="primary" @click="setTime(scope.row)">阅读时间配置</el-link>
                        </span>
                        <span style="margin-right: 5px">
                            <el-link type="primary"
                                @click="$router.push('/evaluation/details/' + id + '/scoreConfiguration/' + scope.row.id)">评分配置</el-link>
                        </span>
                        <span style="margin-right: 5px">
                            <el-link type="primary"
                                @click="$router.push('/evaluation/details/' + id + '/optionConfiguration/' + scope.row.id)">选项配置</el-link>
                        </span>
                        <span style="margin-right: 5px">
                            <el-link type="primary"
                                @click="$router.push('/evaluation/details/' + id + '/editClass/' + scope.row.id)">编辑</el-link>
                        </span>
                        <span style="margin-right: 5px">
                            <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                @confirm="deleted(scope.row)">
                                <el-link slot="reference" type="primary">删除</el-link>
                            </el-popconfirm>
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-batch">
                <span class="fl-l">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>

            <el-dialog title="内容" :visible.sync="dialogVisible" append-to-body width="30%">
                <span style="padding: 10px" v-html="content"></span>
            </el-dialog>
        </div>














        <el-dialog title="阅读时间" :visible.sync="msgDialogVisible" width="20%" append-to-body>
            <div style="padding: 10px;" v-loading="dialogLoading">
                <span slot="footer" class="dialog-footer">
                    <div style="margin: 10px;"><el-input size="mini" v-model="form.spend_time" label="阅读时间">
                            <template slot="append">分钟</template>
                        </el-input></div>

                    <div>
                        <el-button size="mini" @click="msgDialogVisible = false">取 消</el-button>
                        <el-button size="mini" type="primary" :loading="button" @click="modifyTime">确 定</el-button>
                    </div>
                </span>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "detail",
    data() {
        return {
            loading: false,
            name: "",
            id: null,
            pageSize: 10,
            page: 1,
            total: 0,
            tableLoading: false,
            tableData: [],
            content: "",
            dialogVisible: false,
            msgDialogVisible: false,
            dialogLoading: false,
            form: {},
            button: false,
        };
    },
    methods: {
        ...mapActions("evaluation", [
            "getEvaluation",
            "evaluationItemList",
            "delEvaluationItem",
            "getEvaluationItemSpend",
            "saveEvaluationItemSpend"
        ]),
        async modifyTime() {
            this.button = true
            this.form.id = this.form.evaluation_items_id
            const { res_info } = await this.saveEvaluationItemSpend(this.form)
            if (res_info != 'ok') return
            this.$message.success('设置成功')
            this.getList()
            this.button = false
            this.msgDialogVisible = false;
        },
        async setTime(row) {
            this.dialogLoading = true
            this.msgDialogVisible = true;
            const { data } = await this.getEvaluationItemSpend({ id: row.id })
            this.form = data
            this.dialogLoading = false
        },
        viewContent(row) {
            this.dialogVisible = true;
            this.content = row.read_content;
        },
        async deleted(row) {
            const { res_info } = await this.delEvaluationItem({ id: row.id });
            if (res_info != "ok") return;
            this.$message.success("删除成功！");
            this.getList();
        },
        async getList() {
            this.tableLoading = true;
            let form = {
                evaluations_id: this.id,
                page: this.page,
                pageSize: this.pageSize,
            };
            const { data } = await this.evaluationItemList(form);
            this.tableData = data.list;
            this.total = data.total;
            console.log(data);
            this.tableLoading = false;
        },

        setPageSize(pageSize) {
            this.pageSize = pageSize;
            this.getList();
        },
        setPage(page) {
            this.page = page;
            this.getList();
        },
        async initData() {
            this.loading = true;
            const { data } = await this.getEvaluation({ id: this.id });
            this.name = data.name;
            this.getList();
            this.loading = false;
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.initData();
    },
};
</script>

<style>
.course-detail-title {
    margin: 10px;
    background: #fafafa;
    overflow: hidden;
}

.title {
    font-size: 18px;
    margin-top: 5px;
}

.price {
    margin-top: 45px;
    font-size: 16px;
}
</style>
